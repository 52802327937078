import type {MoveDirection, MoveEventType} from "~/utils/types";

export default (type: MoveEventType | undefined, direction: MoveDirection | undefined) => {
	switch (type) {
		case "sale":
			return {
				movedate: "Completion date",
				movedate_confirmed: "Exchanged?",
				movedate_confirmed_short: "Exchanged?",
				movedate_confirmed_label: "Exchanged",
			};
		default:
			return {
				movedate: direction === "out" ? "Tenancy end date" : "Tenancy start date",
				movedate_confirmed: "Is the move date confirmed?",
				movedate_confirmed_short: "Confirmed?",
				movedate_confirmed_label: "Move date confirmed",
			};
	}
}
